import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const findAllParentsByClass = (element, className, stopNode) => {
        let elementParent = element;
        const parents = [];

        // Traverse up the DOM hierarchy until there are no more parent nodes or reaching the stopNode
        while ((elementParent = elementParent.parentNode) && elementParent !== stopNode) {
            if (elementParent.classList.contains(className)) {
                parents.push(elementParent);
            }
        }

        return parents;
    };

    const toggleMenu = (domNode, show) => {
        if (domNode) {
            domNode.style.display = show ? 'block' : 'none';
        }
    };

    const openExpand = (trigger) => {
        trigger.setAttribute('aria-expanded', 'true');
        toggleMenu(trigger.targetMenu, 'true');
    };

    const findCollapsibleTriggerAndOpen = (el) => {
        const collapsibleTrigger = el.querySelector('.cmp-report-navigation__button');
        openExpand(collapsibleTrigger);
    };

    const openMultipleToggleMenus = (nodeList) => {
        [...nodeList].forEach(toggleMenuElement => {
            findCollapsibleTriggerAndOpen(toggleMenuElement);
        });
    };

    const toggleMenus = (element, reportNavigation) => {
        const parentToggleMenus =
            findAllParentsByClass(element, 'js-report-navigation-toggle-menu', reportNavigation);
        openMultipleToggleMenus(parentToggleMenus);
    };

    const expandActiveSections = (activeLink, reportNavigation) => {
        // Check if there is a toggle button next to the link
        const activeLinkSibling = activeLink.nextElementSibling;

        if (activeLinkSibling) {
            // Get the current toggle element
            const currentToggleMenu = activeLink.closest('.js-report-navigation-toggle-menu');

            // Open all parent toggle menus except current one
            if (currentToggleMenu) {
                toggleMenus(currentToggleMenu, reportNavigation);
            }
        } else {
            // Open all parent toggle menus except
            toggleMenus(activeLink, reportNavigation);
        }
    };

    const setActiveLink = (target, reportNavigation) => {
        // Remove all aria-current attributes
        [...reportNavigation.querySelectorAll('a[aria-current]')].forEach(link => {
            link.removeAttribute('aria-current');
        });

        // Set aria-current attribute on clicked link
        target.setAttribute('aria-current', 'true');
    };

    const toggleExpand = (trigger, expanded) => {
        trigger.setAttribute('aria-expanded', expanded);
        toggleMenu(trigger.targetMenu, expanded);
    };

    // eslint-disable-next-line max-lines-per-function
    const initDisclosureNav = (reportNavigation) => {
        const toggleButtons = [...reportNavigation.querySelectorAll('button[aria-expanded][aria-controls]')];
        const reportLinks = [...reportNavigation.querySelectorAll('a')];

        const getActiveLinks = (reportNavigationElement) => {
            const currentPageURL = new URL(window.location.href);
            const activePageLink = currentPageURL.pathname + currentPageURL.hash;
            return [...reportNavigationElement.querySelectorAll(`a[href$="${activePageLink}"]`)];
        };

        const activeNavigationLinks = getActiveLinks(reportNavigation);

        const onToggleClick = (event) => {
            const button = event.target;
            const buttonExpanded = button.getAttribute('aria-expanded') === 'true';
            toggleExpand(button, !buttonExpanded);
        };

        // Bind eventlisteners to toggle buttons
        // Show hide toggle menu
        toggleButtons.forEach(node => {
            if (node.tagName.toLowerCase() === 'button' && node.hasAttribute('aria-controls')) {
                const menu = document.getElementById(node.getAttribute('aria-controls'));
                if (menu) {
                    node.targetMenu = menu;
                    node.addEventListener('click', onToggleClick);
                }
            }
        });

        // Bind eventlisteners to report links
        // Set aria current on correct element
        reportLinks.forEach(link => {
            link.addEventListener('click', () => {
                setActiveLink(link, reportNavigation);
            }, false);
        });

        // Remove Aria current from pages
        [...reportNavigation.querySelectorAll('a[aria-current]')].forEach(link => {
            link.removeAttribute('aria-current');
        });

        // Get active section based on URL on pageload
        // Set aria-current to true on active link(s)
        [...activeNavigationLinks].forEach(activeNavigationLink => {
            activeNavigationLink.setAttribute('aria-current', 'true');
            // check if it is in a collapsible section and make sure to open the section
            expandActiveSections(activeNavigationLink, reportNavigation);
        });
    };

    const initializeReportNavigations = (context) => {
        const reportNavigationToggleMenus = context.querySelectorAll('.js-report-navigation');
        [...reportNavigationToggleMenus].forEach(reportNavigationToggleMenu => {
            initDisclosureNav(reportNavigationToggleMenu);
        });
    };

    onDomReady(() => {
        initializeReportNavigations(document);
    });

}());
